import React, { Component } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import LoginForm from "./Components/Login/LoginForm/";
import Dashboard from "./Components/Dashboard/";
import NotFound404 from "./Components/NotFound404";
import { Skeleton } from "antd";

const SourcingSummary = React.lazy(() =>
  import("./Components/Dashboard/Sourcing/SourcingSummary")
);

const StoreUsers = React.lazy(() =>
  import("./Components/Dashboard/StoreUsers/StoreUsersList")
);
const AdminUsers = React.lazy(() =>
  import("./Components/Dashboard/AdminUsers")
);
const AddAdminUser = React.lazy(() =>
  import("./Components/Dashboard/AdminUsers/AddUser")
);
const Orders = React.lazy(() => import("./Components/Dashboard/Orders"));
const Shipments = React.lazy(() => import("./Components/Dashboard/Shipments"));
const LostDamageClaim = React.lazy(() =>
  import("./Components/Dashboard/LostDamageClaim")
);
const VirtualAWBs = React.lazy(() =>
  import("./Components/Dashboard/VirtualAWBs")
);
const Reporting = React.lazy(() => import("./Components/Dashboard/Reporting"));
const Snapshot = React.lazy(() => import("./Components/Dashboard/Snapshot"));
const RequestAccessListing = React.lazy(() =>
  import("./Components/Dashboard/RequestAccessListing")
);

const ProductDrop = React.lazy(() =>
  import("./Components/Dashboard/ProductDrop")
);

const TATReport = React.lazy(() => import("./Components/Dashboard/TATReport"));
const VendorOrganisations = React.lazy(() =>
  import("./Components/Dashboard/Vendors/Organisations")
);
const AddOrganisation = React.lazy(() =>
  import("./Components/Dashboard/Vendors/AddOrganisation")
);
const VendorUsers = React.lazy(() =>
  import("./Components/Dashboard/Vendors/Users")
);
const AddUser = React.lazy(() =>
  import("./Components/Dashboard/Vendors/AddUser")
);
const Jobs = React.lazy(() => import("./Components/Dashboard/Finance/Jobs"));
const ProductCatalog = React.lazy(() =>
  import("./Components/Dashboard/ProductCatalog")
);
const StoreUsersKYC = React.lazy(() =>
  import("./Components/Dashboard/StoreUsers/StoreUsersKYC")
);
const Transactions = React.lazy(() =>
  import("./Components/Dashboard/Finance/Transactions")
);
const NDREscalationReport = React.lazy(() =>
  import("./Components/Dashboard/NDREscalationReport")
);
const WithdrawalRequests = React.lazy(() =>
  import("./Components/Dashboard/Finance/WithdrawalRequests")
);
const TrendingOrders = React.lazy(() =>
  import("./Components/Dashboard/TrendingOrders")
);
const ReportingJobs = React.lazy(() =>
  import("./Components/Dashboard/ReportingJobs")
);
const CustomChalans = React.lazy(() =>
  import("./Components/Dashboard/CustomChalans")
);
const VFProductCatalog = React.lazy(() =>
  import("./Components/Dashboard/VFProductCatalog")
);
const POTDCatalog = React.lazy(() =>
  import("./Components/Dashboard/POTDCatalog")
);
const EmailSearch = React.lazy(() =>
  import("./Components/Dashboard/EmailSearch")
);
const OrderSearch = React.lazy(() =>
  import("./Components/Dashboard/OrderSearch")
);
const GlobalSearch = React.lazy(() =>
  import("./Components/Dashboard/GlobalSearch")
);
const AwbData = React.lazy(() => import("./Components/Dashboard/AwbData"));
const Disputes = React.lazy(() => import("./Components/Dashboard/Disputes"));
const TATsReport = React.lazy(() =>
  import("./Components/Dashboard/TATsReport")
);
const Flags = React.lazy(() => import("./Components/Dashboard/Flags"));
const VendorOrders = React.lazy(() =>
  import("./Components/Dashboard/VendorOrders")
);
const GlobalOrders = React.lazy(() =>
  import("./Components/Dashboard/GlobalOrders")
);
const StoreUserList = React.lazy(() =>
  import("./Components/Dashboard/StoreUsers/NewStoreUserList")
);
const QuotationRequest = React.lazy(() =>
  import("./Components/Dashboard/QuotationRequest")
);
const QuotationRequestSourcing = React.lazy(() =>
  import("./Components/Dashboard/QuotationRequestSourcing")
);
// const LBHPanel = React.lazy(() => import("./Components/Dashboard/LBHPanel"));
const SyncProducts = React.lazy(() =>
  import("./Components/Dashboard/LBHSyncProducts")
);
const Partners = React.lazy(() => import("./Components/Dashboard/Partners"));
const WireTransactionsList = React.lazy(() =>
  import("./Components/Dashboard/Finance/WireTransactionsList")
);
const PartnerInvoices = React.lazy(() =>
  import("./Components/Dashboard/Finance/PartnerInvoices")
);
const ExportData = React.lazy(() =>
  import("./Components/Dashboard/DataExports/ExportData")
);
const Logs = React.lazy(() =>
  import("./Components/Dashboard/DataExports/Logs")
);
const TrendingProducts = React.lazy(() =>
  import("./Components/Dashboard/TrendingProducts")
);
const DataImport = React.lazy(() =>
  import("./Components/Dashboard/DataImport")
);
const SourcingOptimization = React.lazy(() =>
  import("./Components/Dashboard/Sourcing/SourcingOptimization")
);

const FreightOrders = React.lazy(() =>
  import("./Components/Dashboard/FreightOrders")
);

const SourcingSuggestion = React.lazy(() =>
  import("./Components/Dashboard/Sourcing/SourcingSuggestions")
);
const SourcingRequestsNew = React.lazy(() =>
  import("./Components/Dashboard/Sourcing/SourcingRequestsNew")
);

const AllFreightOrders = React.lazy(() =>
  import("./Components/Dashboard/Sourcing/AllFreightOrders")
);

const PurchaseOrdersNew = React.lazy(() =>
  import("./Components/Dashboard/Sourcing/PurchaseOrdersNew")
);

const PendingOrderSourcing = React.lazy(() =>
  import("./Components/Dashboard/Sourcing/PendingOrderSourcing")
);

const PendingOrderInventory = React.lazy(() =>
  import("./Components/Dashboard/Sourcing/PendingOrderInventory")
);

const PricingUpdates = React.lazy(() =>
  import("./Components/Dashboard/PricingUpdates")
);

const VFProductCatalogNew = React.lazy(() =>
  import("./Components/Dashboard/VFProductCatalog/VFProductCatalogNew")
);

const CatalogProduct = React.lazy(() =>
  import("./Components/Dashboard/Catalog/CatalogProduct")
);

const SourcingRequests = React.lazy(() =>
  import("./Components/Dashboard/Sourcing-v2/SourcingRequests")
);

const ApprovedSourcing = React.lazy(() =>
  import("./Components/Dashboard/Sourcing-v2/ApprovedSourcing")
);

const CreateFreightOrder = React.lazy(() =>
  import("./Components/Dashboard/Sourcing-v2/CreateFreightOrder")
);
const VfMediaPage = React.lazy(() => import("./Components/Dashboard/VfMedia"));
const LabelListing = React.lazy(() =>
  import("./Components/Dashboard/VFProductCatalog/Labellisting")
);

// const PricingUpdates = React.lazy(() =>
//   import("./Components/Dashboard/PricingUpdates")
// );
// const VFInventoryListing = React.lazy(() =>
//   import("./Components/Dashboard/InventoryListing/VFInventoryListing")
// );
const VFInventoryListing = React.lazy(() =>
  import("./Components/Dashboard/InventoryListing")
);

const DownloadInvoice = React.lazy(() =>
  import("./Components/Dashboard/DownloadInvoice")
);

// const ClientInventoryListing = React.lazy(() =>
//   import("./Components/Dashboard/InventoryListing/ClientInventoryListing")
// );
const HoldOrders = React.lazy(() =>
  import("./Components/Dashboard/HoldOrders")
);
const FailedWMSOrders = React.lazy(() =>
  import("./Components/Dashboard/FailedWMSOrders")
);
const Ndr = React.lazy(() => import("./Components/Dashboard/Ndr"));
const RateCards = React.lazy(() => import("./Components/Dashboard/RateCards"));
const RateCardGroups = React.lazy(() =>
  import("./Components/Dashboard/RateCardGroups")
);
const QuotationsSupplier = React.lazy(() =>
  import("./Components/Dashboard/Quotations/QuotationsSupplier")
);
const CustomPricing = React.lazy(() =>
  import("./Components/Dashboard/Quotations/CustomPricing")
);

const RequestProductCatalog = React.lazy(() =>
  import("./Components/Dashboard/CatalogRequest/")
);
const ServiceRequest= React.lazy(() => import("./Components/Dashboard/ServiceRequests"))
const CreateBroadcast = React.lazy(() =>
  import("./Components/Dashboard/CreateBroadcast")
);

const EmailBroadcast = React.lazy(() =>
  import("./Components/Dashboard/EmailBroadcast")
);

const PreviewBroadcast = React.lazy(() =>
  import("./Components/Dashboard/AllBroadcast/Preview")
);

const AllBroadcast = React.lazy(() =>
  import("./Components/Dashboard/AllBroadcast")
);

const EmailerListing = React.lazy(() =>
  import("./Components/Dashboard/EmailerListing")
);

const OrderReport = React.lazy(() =>
  import("./Components/Dashboard/BigQueryReport/OrderReport")
);

const PartnersReport = React.lazy(() =>
  import("./Components/Dashboard/BigQueryReport/PartnersReport")
);

const ShipmentReport = React.lazy(() =>
  import("./Components/Dashboard/BigQueryReport/ShipmentReport")
);

const InventryReport = React.lazy(() =>
  import("./Components/Dashboard/BigQueryReport/InventryReport")
);

const TransactionsReport = React.lazy(() =>
  import("./Components/Dashboard/BigQueryReport/TransactionsReport")
);

const ShipmentRequest = React.lazy(() =>
  import("./Components/Dashboard/RequestShipment")
);

const Profile = React.lazy(() => import("./Components/Dashboard/Profile"));

const Tiers = React.lazy(() => import("./Components/Dashboard/Tiers"));

class TitleUpdater extends Component {
  setTitle(props) {
    document.title = props.title || "vFulfill";
  }

  componentDidMount() {
    this.setTitle(this.props);
  }

  componentDidUpdate(pp) {
    if (pp.title !== this.props.title) {
      this.setTitle(this.props);
    }
  }

  render() {
    return this.props.children;
    // return this.props.children ? this.props.children : null;
  }
}

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="content-body">
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <Dashboard {...props} />}
            />
            <Route
              exact
              path="/login"
              render={(props) => (
                <LoginForm {...{ ...props, title: "vFulfill | Login" }} />
              )}
            />

            <Route
              exact
              path="/:geo(in|global)/catalog/catalogproduct/:vfprodid"
              render={(props) => (
                <Dashboard
                  {...{ ...props, title: "vFulfill | Catalog Products" }}
                  hideSidebar
                  showSearch
                  fullWidthNavbar
                  navbarType="search"
                >
                  <React.Suspense fallback={<Skeleton active />}>
                    <CatalogProduct {...props} />
                  </React.Suspense>
                </Dashboard>
              )}
            />

            {/* <Route path="/:geo(in|global)"> */}
            <Dashboard>
              <React.Suspense fallback={<Skeleton active />}>
                <Route
                  exact
                  path="/:geo(in|global)"
                  render={({ match }) => (
                    <Redirect to={`/${match.params.geo}/snapshot`} />
                  )}
                />

                <Route
                  path={`/:geo(in|global)/snapshot`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Snapshot" }}
                    >
                      <Snapshot {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/store-users`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Store Users" }}
                    >
                      {" "}
                      <StoreUsers {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/store-users-list`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Store Users" }}
                    >
                      {" "}
                      <StoreUserList {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/quotation-request`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Quotation Request" }}
                    >
                      {" "}
                      <QuotationRequest {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/sourcing-quotation-request`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Quotation Request" }}
                    >
                      {" "}
                      <QuotationRequestSourcing {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/store-users-kyc`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Store Users KYC",
                      }}
                    >
                      {" "}
                      <StoreUsersKYC {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/admins`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Admin Users" }}
                    >
                      {" "}
                      <AdminUsers {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/partners`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Partners" }}
                    >
                      {" "}
                      <Partners {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/finance/download-invoice`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Download Invoice" }}
                    >
                      {" "}
                      <DownloadInvoice {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/lbh-sync-product`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | LBH Sync Product",
                      }}
                    >
                      {" "}
                      <SyncProducts {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/add-admin`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Add Admin User" }}
                    >
                      <AddAdminUser {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/edit-admin-user/:id`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Edit Admin User",
                      }}
                    >
                      <AddAdminUser {...props} edit={true} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/vendor-organisations`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Vendor Organisations",
                      }}
                    >
                      <VendorOrganisations {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/export-data`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Export Data" }}
                    >
                      <ExportData {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/import-data`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Import Data" }}
                    >
                      <DataImport {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/logs`}
                  render={(props) => (
                    <TitleUpdater {...{ ...props, title: "vFulfill | Logs" }}>
                      <Logs {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/trending-products`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Trending Products",
                      }}
                    >
                      <TrendingProducts {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/add-vendor-organisation`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Add Organisations",
                      }}
                    >
                      <AddOrganisation {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/vendor-users`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Vendor Users" }}
                    >
                      <VendorUsers {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/add-vendor-user`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Add Vendor User",
                      }}
                    >
                      <AddUser {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/edit-vendor-user/:id`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Edit Vendor User",
                      }}
                    >
                      <AddUser {...props} edit={true} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/orders`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | All Orders" }}
                    >
                      {" "}
                      <Orders {...props} pageType="allorders" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/open-orders`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | All Orders" }}
                    >
                      {" "}
                      <Orders {...props} pageType="openorders" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/quotations-supplier`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Quotation Supplier" }}
                    >
                      {" "}
                      <QuotationsSupplier
                        {...props}
                        pageType="QuotationsSupplier"
                      />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/custom-pricing`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Custom Pricing Calculator",
                      }}
                    >
                      {" "}
                      <CustomPricing {...props} pageType="custompricing" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path="/profile"
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | My Profile",
                      }}
                    >
                      {" "}
                      <Profile {...props} pageType="profile" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/vforders`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | All Orders" }}
                    >
                      {" "}
                      <Orders {...props} pageType="allorders" orderType="vf" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/open-vforders`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | All Orders" }}
                    >
                      {" "}
                      <Orders {...props} pageType="openorders" orderType="vf" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/hold-orders`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Hold Orders" }}
                    >
                      {" "}
                      <HoldOrders {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/failed-wms-orders`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Failed WMS Orders" }}
                    >
                      {" "}
                      <FailedWMSOrders {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/shipments`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Shipments" }}
                    >
                      {" "}
                      <Shipments {...props} pageType="allorders" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/lost_damaged_claim`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Lost & Damaged Shipments",
                      }}
                    >
                      {" "}
                      <LostDamageClaim
                        {...props}
                        pageType="lost_damaged_claim"
                      />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/virtual_awbs`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Virtual AWBs" }}
                    >
                      {" "}
                      <VirtualAWBs {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/reporting`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Reporting" }}
                    >
                      {" "}
                      <Reporting {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/inventory/vf-inventory`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | VF Inventory" }}
                    >
                      {" "}
                      <VFInventoryListing {...props} />
                    </TitleUpdater>
                  )}
                />
                {/* <Route
                  exact
                  path={`/:geo(in|global)/inventory/client-inventory`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Client Inventory",
                      }}
                    >
                      {" "}
                      <ClientInventoryListing {...props} />
                    </TitleUpdater>
                  )}
                /> */}
                {/* <Route
                  exact
                  path={`/:geo(in|global)/request-access-submits`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Access Requests",
                      }}
                    >
                      {" "}
                      <RequestAccessListing {...props} />
                    </TitleUpdater>
                  )}
                /> */}
                <Route
                  exact
                  path={`/:geo(in|global)/tat-report`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | TAT Report" }}
                    >
                      {" "}
                      <TATReport {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/tats-report`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | TAT Report" }}
                    >
                      {" "}
                      <TATsReport {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/flags`}
                  render={(props) => (
                    <TitleUpdater {...{ ...props, title: "vFulfill | Flags" }}>
                      {" "}
                      <Flags {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/request-access-submits`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Access Requests",
                      }}
                    >
                      {" "}
                      <RequestAccessListing {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/products-drop`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Product Drop",
                      }}
                    >
                      {" "}
                      <ProductDrop {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/vendor-open-order`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Vendor Open Orders",
                      }}
                    >
                      {" "}
                      <VendorOrders {...props} pageType="openorders" />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/vendor-all-order`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Vendor All Orders",
                      }}
                    >
                      {" "}
                      <VendorOrders {...props} pageType="allorders" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/global/global-open-order`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | International Open Orders",
                      }}
                    >
                      {" "}
                      <GlobalOrders {...props} pageType="openorders" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/global/global-all-order`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | International All Orders",
                      }}
                    >
                      {" "}
                      <GlobalOrders {...props} pageType="allorders" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/finance/jobs`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Finance Jobs" }}
                    >
                      {" "}
                      <Jobs {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/finance/transactions`}
                  key="in_transactions"
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Finance Transactions",
                      }}
                    >
                      {" "}
                      <Transactions {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/global/finance/global_transactions`}
                  key="global_transactions"
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | International Finance Transactions",
                      }}
                    >
                      {" "}
                      <Transactions {...props} currency="USD" />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/finance/withdrawal-requests`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Withdrawal Requests",
                      }}
                    >
                      {" "}
                      <WithdrawalRequests {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/finance/wire-transaction-list`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Wire Transaction List",
                      }}
                    >
                      {" "}
                      <WireTransactionsList {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/finance/partner-invoice-list`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Partner Invoice List",
                      }}
                    >
                      {" "}
                      <PartnerInvoices {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/ali-product-catalog`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | To Be Cataloged",
                      }}
                    >
                      {" "}
                      <ProductCatalog {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/vf-media`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | vf Media",
                      }}
                    >
                      {" "}
                      <VfMediaPage {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/product-catalog`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | vF Product Catalog",
                      }}
                    >
                      {" "}
                      <VFProductCatalog {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/products/VFProductCatalogNew/:vfprodid`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | vF Product Catalog",
                      }}
                    >
                      {" "}
                      <VFProductCatalogNew {...props} />
                    </TitleUpdater>
                  )}
                />

                {/* <Route
                  exact
                  path={`/products/PreviewProduct/:vfprodid`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Product Catalog",
                      }}
                    >
                      {" "}
                      <PreviewProduct {...props} />
                    </TitleUpdater>
                  )}
                /> */}
                <Route
                  exact
                  path={`/:geo(in|global)/potd-catalog`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | POTD Nominee" }}
                    >
                      {" "}
                      <POTDCatalog {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/awb-data`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | AWB Data" }}
                    >
                      {" "}
                      <AwbData {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/partner-escalations`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Disputes" }}
                    >
                      {" "}
                      <Disputes {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/shipment-request`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | shipment-request" }}
                    >
                      {" "}
                      <ShipmentRequest {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/broadcast`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | broadcast" }}
                    >
                      {" "}
                      <CreateBroadcast {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/email-broadcast`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Email-Broadcast" }}
                    >
                      {" "}
                      <EmailBroadcast {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/all-broadcast`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | All broadcast" }}
                    >
                      {" "}
                      <AllBroadcast {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/emailer-listing`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Emailer listing" }}
                    >
                      {" "}
                      <EmailerListing {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path="/:geo(in|global)/all-broadcast/:vfprodid"
                  render={(props) => <PreviewBroadcast />}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/trending-orders`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Trending Orders",
                      }}
                    >
                      {" "}
                      <TrendingOrders {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/ndr-escalation-report`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | NDR Escalation Report",
                      }}
                    >
                      {" "}
                      <NDREscalationReport {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/ndr/all`}
                  render={(props) => (
                    <TitleUpdater {...{ ...props, title: "vFulfill | NDR" }}>
                      {" "}
                      <Ndr {...props} status="" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/ndr/open`}
                  render={(props) => (
                    <TitleUpdater {...{ ...props, title: "vFulfill | NDR" }}>
                      {" "}
                      <Ndr {...props} status="pending" />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/ndr/responded`}
                  render={(props) => (
                    <TitleUpdater {...{ ...props, title: "vFulfill | NDR" }}>
                      {" "}
                      <Ndr {...props} status="responded" />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/ndr/expired`}
                  render={(props) => (
                    <TitleUpdater {...{ ...props, title: "vFulfill | NDR" }}>
                      {" "}
                      <Ndr {...props} status="expired" />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/reporting-jobs`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Reporting Jobs" }}
                    >
                      {" "}
                      <ReportingJobs {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/custom-chalans`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Custom Chalans" }}
                    >
                      {" "}
                      <CustomChalans {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/email-search`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Email Search" }}
                    >
                      {" "}
                      <EmailSearch {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/order-search`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Order Search" }}
                    >
                      {" "}
                      <OrderSearch {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/sourcing-optimization`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Sourcing Optimization",
                      }}
                    >
                      {" "}
                      <SourcingOptimization {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/sourcing-request`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Sourcing Requests",
                      }}
                    >
                      {" "}
                      <SourcingRequests {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/approved-sourcing`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Approved Sourcing",
                      }}
                    >
                      {" "}
                      <ApprovedSourcing {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/freight-orders1`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Create Freight Order",
                      }}
                    >
                      {" "}
                      <CreateFreightOrder {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/freight-orders`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Freight-orders",
                      }}
                    >
                      {" "}
                      <FreightOrders {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/suggested-sourcing`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Sourcing Suggestion",
                      }}
                    >
                      {" "}
                      <SourcingSuggestion {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/sourcing-summary`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Sourcing Summary",
                      }}
                    >
                      {" "}
                      <SourcingSummary {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/mandatory-sourcing`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Mandatory Sourcing",
                      }}
                    >
                      {" "}
                      <SourcingRequestsNew {...props} type="mandatory" />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/purchase-orders`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Purchase Orders",
                      }}
                    >
                      {" "}
                      <PurchaseOrdersNew {...props} type="mandatory" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/pending-orders-sourcing`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Pending Orders Sourcing",
                      }}
                    >
                      {" "}
                      <PendingOrderSourcing {...props} type="mandatory" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/pending-orders-inventory`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Pending Orders Inventory",
                      }}
                    >
                      {" "}
                      <PendingOrderInventory {...props} type="mandatory" />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/sourcing-requests`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Sourcing Requests",
                      }}
                    >
                      {" "}
                      <SourcingRequestsNew
                        from={"sourcing"}
                        {...props}
                        type="sourcing-requests"
                      />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/catalog-requests`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Catalog Requests",
                      }}
                    >
                      {" "}
                      <RequestProductCatalog {...props} />
                    </TitleUpdater>
                  )}
                />
                     <Route
                  exact
                  path={`/:geo(in|global)/service-requests`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Service Requests",
                      }}
                    >
                      {" "}
                      <ServiceRequest {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/AllFreightOrders`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | All Freight Orders",
                      }}
                    >
                      {" "}
                      <AllFreightOrders {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/pricing-updates`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Pricing Updates",
                      }}
                    >
                      {" "}
                      <PricingUpdates {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/label-listing`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | label-listing",
                      }}
                    >
                      {" "}
                      <LabelListing {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/global-search`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Global Search" }}
                    >
                      {" "}
                      <GlobalSearch {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/rate-cards`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Rate Cards" }}
                    >
                      {" "}
                      <RateCards {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/rate-card-groups`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Rate Card Groups",
                      }}
                    >
                      {" "}
                      <RateCardGroups {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/tiers`}
                  render={(props) => (
                    <TitleUpdater {...{ ...props, title: "vFulfill | Tiers" }}>
                      {" "}
                      <Tiers {...props} />
                    </TitleUpdater>
                  )}
                />
                <Route
                  exact
                  path={`/:geo(in|global)/orders-report`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Order Report" }}
                    >
                      {" "}
                      <OrderReport {...props} />
                    </TitleUpdater>
                  )}
                />

                {/* partner report  */}
                <Route
                  exact
                  path={`/:geo(in|global)/partners-report`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Partner Report" }}
                    >
                      {" "}
                      <PartnersReport {...props} />
                    </TitleUpdater>
                  )}
                />

                {/* InventryReport */}

                <Route
                  exact
                  path={`/:geo(in|global)/shipments-report`}
                  render={(props) => (
                    <TitleUpdater
                      {...{ ...props, title: "vFulfill | Shipment Report" }}
                    >
                      {" "}
                      <ShipmentReport {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/smart-inventory-report`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Smart Inventory Report",
                      }}
                    >
                      {" "}
                      <InventryReport {...props} />
                    </TitleUpdater>
                  )}
                />

                <Route
                  exact
                  path={`/:geo(in|global)/transactions-report`}
                  render={(props) => (
                    <TitleUpdater
                      {...{
                        ...props,
                        title: "vFulfill | Transactions Report",
                      }}
                    >
                      {" "}
                      <TransactionsReport {...props} />
                    </TitleUpdater>
                  )}
                />
              </React.Suspense>
            </Dashboard>
            {/* </Route> */}
            <Route path="*" render={() => <NotFound404 />} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
